@font-face {
  font-family: 'yme';
  src: url('../fonts/yme.eot');
  src: url('../fonts/yme.eot') format('embedded-opentype'),
  url('../fonts/yme.woff') format('woff'),
  url('../fonts/yme.ttf') format('truetype'),
  url('../fonts/yme.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'yme';
    src: url('../font/yme.svg?90615345#yme') format('svg');
  }
}
*/

[class^="evycon-"]:before, [class*="evycon-"]:before {
  display: inline-block;
  font-family: 'yme';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
}

.evycon-A:before {content: '\0041';}

.evycon-B:before {content: '\0042';}

.evycon-C:before {content: '\0043';}

.evycon-D:before {content: '\0044';}

.evycon-E:before {content: '\0045';}

.evycon-F:before {content: '\0046';}

.evycon-G:before {content: '\0047';}

.evycon-H:before {content: '\0048';}

.evycon-I:before {content: '\0049';}

.evycon-J:before {content: '\004a';}

.evycon-K:before {content: '\004b';}

.evycon-L:before {content: '\004c';}

.evycon-M:before {content: '\004d';}

.evycon-N:before {content: '\004e';}

.evycon-O:before {content: '\004f';}

.evycon-a:before {content: '\0061';}

.evycon-b:before {content: '\0062';}

.evycon-c:before {content: '\0063';}

.evycon-d:before {content: '\0064';}

.evycon-e:before {content: '\0065';}

.evycon-g:before {content: '\0067';}

.evycon-h:before {content: '\0068';}

.evycon-i:before {content: '\0069';}

.evycon-j:before {content: '\006a';}

.evycon-k:before {content: '\006b';}

.evycon-l:before {content: '\006c';}

.evycon-m:before {content: '\006d';}

.evycon-o:before {content: '\006f';}

.evycon-p:before {content: '\0070';}

.evycon-q:before {content: '\0071';}

.evycon-r:before {content: '\0072';}

.evycon-s:before {content: '\0073';}

.evycon-t:before {content: '\0074';}

.evycon-u:before {content: '\0075';}

.evycon-v:before {content: '\0076';}

.evycon-w:before {content: '\0077';}

.evycon-x:before {content: '\0078';}

.evycon-y:before {content: '\0079';}

.evycon-z:before {content: '\007a';}
